import React, { useMemo } from "react";
import _ from "lodash";
import { Rate } from "@trnsact/ntp-sdk";
import { useSelector } from "react-redux";
import { formatNumberWithDelimiter } from "utils";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { Addons } from "./Addons";
import { Fields } from "./Fields";
import { vendorsLogoByType } from "../../../../../constants";
import { deskingSelectors } from "modules/desking/model/selectors";
import { FormattedLossCode } from "modules/desking/lib/lossCodesFormatter";
import { MarkupType, ProposalProductCardByVendorProps, ProposalProductCardModes } from "../../../../../types";
import { ProposalCardContainer } from "../cardContainer/ProposalCardContainer";
import PREMIUM_2000_LOGO from "../../../../../../../assets/img/aftermarket-vendor-logos/premium-2000-logo.png";

export const Card = ({
  mode,
  product,
  type = "simple",
  equipmentCost,
  prisingConfig,
  proposalProductConfiguration,
  updateProductConfiguration,
}: ProposalProductCardByVendorProps) => {
  const products = useSelector(deskingSelectors.proposalsProducts) as ProposalProduct[];

  const addons =
    proposalProductConfiguration?.addons?.map((a: any) => {
      let data: { API_RESPONSE_DATA: Rate } =
        product?.aftermarketProduct?.criteria?.[0]?.event?.params?.AFTERMARKET_PRODUCT_VENDOR_API_DATA;

      if (!data) {
        const productStoredInTrnsactFromApi = products.find(p => p.proposalProductId === product.proposalProductId);
        const productFromNtpApi = products.find(p => {
          return p.aftermarketProduct?.productName === product.aftermarketProduct?.productName;
        });
        const productFromApi = productStoredInTrnsactFromApi || productFromNtpApi;
        if (productFromApi) {
          data = productFromApi.aftermarketProduct?.criteria?.[0]?.event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA;
        }
      }

      const term = data?.API_RESPONSE_DATA?.terms.find((termData: any) => {
        return (
          termData.termMonths === proposalProductConfiguration?.coverage?.termMonths &&
          termData.termOdometer === proposalProductConfiguration?.coverage?.termOdometer
        );
      });

      const lossCode = term?.components
        .map((component: any) => component.lossCodes)
        .flat()
        .find((lc: any) => lc.code === a.thirdPartyId);

      return { ...a, cost: lossCode?.dealerCost ?? 0, retailCost: lossCode?.dealerCost ?? 0 };
    }) ?? [];

  const { deductibleOptions, mileageOptions, addonsOptions, logo } = useMemo(() => {
    let data: { API_RESPONSE_DATA: Rate } =
      product?.aftermarketProduct?.criteria?.[0]?.event?.params?.AFTERMARKET_PRODUCT_VENDOR_API_DATA;

    if (!data) {
      const productStoredInTrnsactFromApi = products.find(p => p.proposalProductId === product.proposalProductId);
      const productFromNtpApi = products.find(p => {
        return p.aftermarketProduct?.productName === product.aftermarketProduct?.productName;
      });
      const productFromApi = productStoredInTrnsactFromApi || productFromNtpApi;
      if (productFromApi) {
        data = productFromApi.aftermarketProduct?.criteria?.[0]?.event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA;
      }
    }

    const accessKey = `${proposalProductConfiguration?.coverage?.termMonths}-${proposalProductConfiguration?.coverage?.termOdometer}`;

    const addonsOptions = data?.API_RESPONSE_DATA.terms.reduce<any>((acc, data) => {
      const key = `${data.termMonths}-${data.termOdometer}`;

      acc[key] = data;

      return acc;
    }, {})?.[accessKey];

    const term = data?.API_RESPONSE_DATA?.terms.find((termData: any) => {
      return (
        termData.termMonths === proposalProductConfiguration?.coverage?.termMonths &&
        termData.termOdometer === proposalProductConfiguration?.coverage?.termOdometer
      );
    });

    const lossCodes: any[] = [];

    addonsOptions?.components.forEach((component: any) => {
      component.lossCodes.forEach((lossCode: any) => {
        lossCodes.push(lossCode);
      });
    });

    let cost = term ? term.dealerCost : 0;
    let retailCost = term ? term.dealerCost : 0;

    const needToUpdateProductConfiguration =
      mode !== ProposalProductCardModes.Constructor &&
      (proposalProductConfiguration.cost === 0 || proposalProductConfiguration.retailCost === 0);

    addons.forEach((addon: any) => {
      const lossCode = lossCodes.find(lc => lc.code === addon.thirdPartyId);

      if (lossCode) {
        cost += lossCode.dealerCost;
        retailCost += lossCode.suggestedRetailCost;
      }
    });

    if (needToUpdateProductConfiguration) {
      updateProductConfiguration({
        retailCost,
        cost,
        coverage: term,
        addons,
      });
    }

    return {
      logo: data?.API_RESPONSE_DATA.groupCode !== "P2K" ? vendorsLogoByType["NTP"] : PREMIUM_2000_LOGO,
      deductibleOptions: _.uniqBy(data?.API_RESPONSE_DATA.terms, term => term.deductible.amount).map(
        term => term.deductible.amount
      ),
      mileageOptions:
        proposalProductConfiguration?.deductible || proposalProductConfiguration?.deductible === 0
          ? data?.API_RESPONSE_DATA.terms.filter(
              term => term.deductible.amount === proposalProductConfiguration?.deductible
            )
          : [],
      addonsOptions: addonsOptions,
      cost,
      retailCost,
    };
  }, [product, proposalProductConfiguration]);

  const handleDeductibleChange = (selectedAmount: number) => {
    updateProductConfiguration({
      coverage: null,
      deductible: selectedAmount,
    });
  };

  const formatCoverageTerm = (term: any): string => {
    return `${term.termMonths} Months / ${formatNumberWithDelimiter(term.termOdometer)} Miles`;
  };

  const handleMileageChange = (selectedTerm: any) => {
    let nextAddons = [...addons];

    const lossCodes = selectedTerm.components.map((component: any) => component.lossCodes).flat();

    nextAddons.forEach((addon: any, addonIndex) => {
      const lossCodeIndex = lossCodes.findIndex((lc: any) => addon.thirdPartyId === lc.code);

      if (lossCodeIndex !== -1) {
        nextAddons[addonIndex].cost = lossCodes[lossCodeIndex].dealerCost;
        nextAddons[addonIndex].retailCost = lossCodes[lossCodeIndex].dealerCost;
      }
    });

    const addonsCost = addons.reduce((acc: number, addon: any) => acc + addon.cost, 0);

    const markupToUse = proposalProductConfiguration?.markup;
    let retailCost = selectedTerm?.dealerCost ?? 0;

    if (markupToUse) {
      if (markupToUse.type === MarkupType.Percentage) {
        retailCost = _.round((selectedTerm?.dealerCost + addonsCost) * (1 + markupToUse.markup / 100), 2);
      } else {
        retailCost = selectedTerm?.dealerCost + addonsCost + markupToUse.markup;
      }
    }

    updateProductConfiguration({
      coverage: selectedTerm,
      retailCost,
      cost: (selectedTerm?.dealerCost ?? 0) + addonsCost,
      markup: markupToUse ? markupToUse : { type: MarkupType.Percentage, markup: 0 },
      COVERAGE_TERM: formatCoverageTerm(selectedTerm),
      addons: nextAddons,
    });
  };

  const handleCheckboxChange = (formattedLossCodes: FormattedLossCode[]) => {
    if (!proposalProductConfiguration?.coverage) return;

    let nextAddons = [...addons];

    formattedLossCodes.forEach(lossCode => {
      const lossCodeIndex = nextAddons.findIndex((addon: any) => addon.thirdPartyId === lossCode.thirdPartyId);

      if (lossCodeIndex === -1 && lossCode.value === true) {
        nextAddons = [...nextAddons, lossCode];
      } else {
        nextAddons = nextAddons.filter(
          (addon: any) =>
            addon.thirdPartyId !== lossCode.thirdPartyId ||
            (addon.thirdPartyId !== lossCode.thirdPartyId && lossCode.value === false)
        );
      }
    });

    const addonsCost = nextAddons.reduce((acc: number, addon: any) => acc + addon.cost, 0);

    const newCost = (proposalProductConfiguration?.coverage.dealerCost ?? 0) + addonsCost;

    let newRetailCost = newCost;

    const markupToUse = proposalProductConfiguration?.markup;

    if (markupToUse) {
      if (markupToUse.type === MarkupType.Percentage) {
        newRetailCost = _.round(newRetailCost * (1 + markupToUse.markup / 100), 2);
      } else {
        newRetailCost = newRetailCost + markupToUse.markup;
      }
    }

    updateProductConfiguration({
      cost: newCost,
      markup: markupToUse,
      retailCost: newRetailCost,
      addons: nextAddons,
    });
  };

  return (
    <ProposalCardContainer
      mode={mode}
      type={type}
      logo={logo}
      factsSkipped={[]}
      proposalProduct={product}
      prisingConfig={prisingConfig}
      equipmentCost={equipmentCost}
      productConfiguration={proposalProductConfiguration}
      updateProductConfiguration={updateProductConfiguration}>
      <Fields
        disableAllFields={false}
        uniqueDeductibles={deductibleOptions}
        filteredMileageOptions={mileageOptions}
        handleMileageChange={handleMileageChange}
        configuration={proposalProductConfiguration}
        handleDeductibleChange={handleDeductibleChange}
        formatCoverageTerm={formatCoverageTerm}
      />

      {addonsOptions && (
        <Addons addons={addons} addonsOptions={addonsOptions} handleCheckboxChange={handleCheckboxChange} />
      )}
    </ProposalCardContainer>
  );
};
