import { Location } from "@trnsact/trnsact-shared-types";
import { IntegrationSettingsDealerNumberByLocationForm, IntegrationSettingsFormInputs } from "../types";
import { keyBy } from "utils/arrayUtils";

export const getDealerNumberByLocationFormData = (
  locations: Location[],
  integrationSettingsValuesList: any
): IntegrationSettingsDealerNumberByLocationForm[] => {
  if (!locations?.length || !integrationSettingsValuesList) {
    return [];
  }

  const isvByLocationId = keyBy(integrationSettingsValuesList, "locationId");
  return locations?.map(location => {
    const locationIntegrationSettingsValues = isvByLocationId[location.locationId!];
    return {
      location: {
        locationId: location.locationId!,
        locationName: `${location.locationName!} (${location.category})`,
      },
      dealerNumber: locationIntegrationSettingsValues?.integrationSettingsValues?.dealerNumber,
      integrationSettingsValuesId: locationIntegrationSettingsValues?.integrationSettingsValuesId,
    };
  });
};

export const formatIntegrationSettingsFormDataToPayload = (formData: IntegrationSettingsFormInputs) => {
  return formData.dealerNumberByLocation.reduce((acc: any, row) => {
    // to exclude non touched rows
    if (row.integrationSettingsValuesId || row.dealerNumber) {
      const payloadItem = {
        integrationSettingsValuesId: row.integrationSettingsValuesId,
        integrationName: "NTP",
        locationId: row.location.locationId,
        integrationSettingsValues: {
          dealerNumber: row.dealerNumber,
        },
      };
      return [...acc, payloadItem];
    }
    return acc;
  }, []);
};
