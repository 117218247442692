import React from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { vendorsLogoByType } from "../../../../constants";
import { ProposalProductCard } from "../ProposalProductCard";
import PREMIUM_2000_LOGO from "../../../../../../assets/img/aftermarket-vendor-logos/premium-2000-logo.png";
import { AftermarketProductTypes, MenuOptionCardBodyProps } from "../../../../types";

export const MenuOptionCardCommon = ({
  mode,
  menuOption,
  onRemoveAddon,
  onRemoveProduct,
  onSwitchProducts,
  productsDropContainer,
  onOpenProductDetailsDialog,
  productsConfiguration = {},
}: MenuOptionCardBodyProps) => {
  const classes = useStyles();

  function getProductLogo(product: any) {
    try {
      if (
        product.aftermarketProduct?.aftermarketVendorApiChannel === AftermarketProductTypes.Ntp &&
        product.aftermarketProduct?.externalId.substring(0, 3) === "P2K"
      ) {
        return PREMIUM_2000_LOGO;
      }
    } catch (e: any) {
      console.error(e);
    }
    return vendorsLogoByType?.[product?.aftermarketProduct?.aftermarketVendorApiChannel as AftermarketProductTypes];
  }

  return (
    <>
      <Box className={classes.dnd} {...({ ref: productsDropContainer } as any)}>
        {!menuOption?.products.length ? (
          <Typography component="span" variant="body1" color="textSecondary">
            Drag & drop options here
          </Typography>
        ) : (
          <Box className={classes.proposalProductCards}>
            {menuOption?.products.length &&
              menuOption?.products.map((product: any, index: number) => (
                <ProposalProductCard
                  key={index}
                  mode={mode}
                  index={index}
                  product={product}
                  onOpenProductDetailsDialog={() => onOpenProductDetailsDialog?.(product)}
                  productConfiguration={productsConfiguration?.[product.proposalProductId]}
                  onRemoveAddon={title => onRemoveAddon?.(product, menuOption.name, title)}
                  onSwitchProducts={(from, to) => onSwitchProducts({ menuName: menuOption.name, from, to })}
                  onRemoveProduct={() => onRemoveProduct?.(product.proposalProductId, menuOption)}
                  logo={getProductLogo(product)}
                />
              ))}
          </Box>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  dnd: {
    flex: 1,
    display: "flex",
    padding: "4px 0",
    alignItems: "center",
    justifyContent: "center",
  },
  proposalProductCards: {
    gap: "4px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));
