import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import * as moment from "moment";
import { Fields } from "./Fields";
import { deskingSelectors } from "../../../../../model";
import { vendorsLogoByType } from "../../../../../constants";
import { ProposalCardContainer } from "../cardContainer/ProposalCardContainer";
import {
  FinanceProgramFormValues,
  MarkupType,
  ProposalProductCardByVendorProps,
  ProposalProductCardModes,
} from "../../../../../types";
import {
  GaigAvailableAsset,
  GaigCreateQuoteOptions,
  GaigCreateQuoteResponse,
  GaigProgramCode,
} from "@trnsact/aftermarket-vendor-sdk-gaig";
import { GET_DYNAMIC_AFTERMARKET_PRODUCT_PRICING } from "modules/desking/api/getAftermarketProductDynamicPricing";
import { AftermarketProductVendorApiChannel } from "@trnsact/trnsact-shared-types";
import Delta from "quill-delta";
import { useFormContext, useWatch } from "react-hook-form";
import { formatCurrency } from "utils";
import { CreditApplication, ProposalProduct, VendorProfile } from "@trnsact/trnsact-shared-types/dist/generated";
import { v4 } from "uuid";

const GAIG_CATEGORIES = [
  {
    category: "Compact Construction",
    assetClass: "G2",
  },
  {
    category: "Heavy Construction",
    assetClass: "G1",
  },
  {
    category: "Lifts",
    assetClass: "G3",
  },
  {
    category: "Portable Construction",
    assetClass: "I4",
  },
  {
    category: "Utility Construction",
    assetClass: "G6",
  },
  {
    category: "Class 1-2",
    assetClass: "V1",
  },
  {
    category: "Class 3-5",
    assetClass: "V2",
  },
  {
    category: "Class 6-7",
    assetClass: "V4",
  },
  {
    category: "Class 8",
    assetClass: "V6",
  },
  {
    category: "Utility Trailer",
    assetClass: "V7",
  },
  {
    category: "Trailer",
    assetClass: "V8",
  },
  {
    category: "Semi-Trailer",
    assetClass: "V9",
  },
];

export const Card = ({
  mode,
  product,
  type = "simple",
  equipmentCost,
  prisingConfig,
  updateProductConfiguration,
  proposalProductConfiguration,
}: ProposalProductCardByVendorProps) => {
  const creditApp: CreditApplication = useSelector((state: any) => state.creditApp) as CreditApplication;
  const vendorProfile: VendorProfile = useSelector((state: any) => state.vp) as VendorProfile;
  const [isDynamicPricingLoading, setLoadingDynamicPricing] = useState(false);
  const products = useSelector(deskingSelectors.proposalsProducts) as ProposalProduct[];

  const { control } = useFormContext<FinanceProgramFormValues>();
  const [startDateValue, amountValue] = useWatch({ control, name: ["financeQuote.startDate", "financeQuote.amount"] });

  const [originalParagraphDescription] = useState(
    product.aftermarketProduct?.aftermarketProductCatalogDetails?.paragraphDescription
  );

  const [originalProductSummary] = useState(
    product.aftermarketProduct?.aftermarketProductCatalogDetails?.productSummary
  );

  const createParagraphDescriptionDelta = (newConfiguration: any, finalRetailPricePerMonth: number) => {
    const paragraphDescriptionDelta = new Delta();
    const items = [
      // newConfiguration.selectedProgram.ProgramDescription,
      `Coverages: ${
        newConfiguration.contractResponse.data.portfolios
          ? newConfiguration.contractResponse.data.portfolios[0].coverages.join(", ")
          : ""
      }`,
      `${formatCurrency(finalRetailPricePerMonth, true)} per month for ${newConfiguration.contractTerm} months`,
    ];
    items.forEach(item => {
      if (_.isString(item)) {
        paragraphDescriptionDelta.insert(`${item}\n`);
      } else {
        // paragraphDescriptionDelta.insert(item.insert, item);
      }
    });
    if (originalParagraphDescription) {
      paragraphDescriptionDelta.ops = paragraphDescriptionDelta.ops.concat(originalParagraphDescription.ops);
    }
    return paragraphDescriptionDelta;
  };

  const createProductSummaryDelta = (newConfiguration: any, finalRetailPricePerMonth: number) => {
    const productSummaryDelta = new Delta();
    const items = [
      newConfiguration.selectedProgram.ProgramDescription,
      { insert: `You are covered for ${newConfiguration.coverageTerm} months `, bold: true },
    ];
    items.forEach(item => {
      if (_.isString(item)) {
        productSummaryDelta.insert(`${item}\n`);
      } else {
        productSummaryDelta.insert(item.insert, item);
      }
    });
    if (originalProductSummary) {
      productSummaryDelta.ops = productSummaryDelta.ops.concat(originalProductSummary.ops);
    }
    return productSummaryDelta;
  };

  const createProductCoverageDelta = (newConfiguration: any) => {
    const coverageDelta = new Delta();
    const bulletedItems = [
      newConfiguration.selectedProgram.ProgramDescriptionExternal,
      `Contract Dates: ${newConfiguration.contractResponse.data.contractStartDate} to ${newConfiguration.contractResponse.data.contractEndDate}`,
      `Coverage Term: ${proposalProductConfiguration.coverageTerm} months`,
      `Quote ID: ${newConfiguration.contractResponse.data.quoteID}`,
    ];
    bulletedItems.forEach(item => {
      coverageDelta.insert(`${item}\n`, { list: "bullet" });
    });
    return coverageDelta;
  };

  const [getDynamicPricing] = useLazyQuery<
    { aftermarketProductDynamicPricing: { success: boolean; errorMessage?: string; data: GaigCreateQuoteResponse } },
    { input: { aftermarketVendorApiChannel: AftermarketProductVendorApiChannel; gaigData: GaigCreateQuoteOptions } }
  >(GET_DYNAMIC_AFTERMARKET_PRODUCT_PRICING, {
    notifyOnNetworkStatusChange: true,
    onCompleted(response) {
      if (response.aftermarketProductDynamicPricing.success) {
        const totalMonthlyAmount = response?.aftermarketProductDynamicPricing?.data?.TotalMonthlyAmount || 0;
        const totalFeePerInvoice = response?.aftermarketProductDynamicPricing?.data?.TotalFeePerInvoice || 0;

        const finalRetailPricePerMonth = totalMonthlyAmount + totalFeePerInvoice;

        const configuration = {
          ...proposalProductConfiguration,
          contractResponse: response?.aftermarketProductDynamicPricing,
        };

        const productSummaryDelta = createProductSummaryDelta(configuration, finalRetailPricePerMonth);

        try {
          _.set(product, "aftermarketProduct.aftermarketProductCatalogDetails.productSummary", productSummaryDelta);
        } catch (e) {
          console.error("Error updating summary details", e);
        }

        const productParagraphDescriptionDelta = createParagraphDescriptionDelta(
          configuration,
          finalRetailPricePerMonth
        );
        try {
          _.set(
            product,
            "aftermarketProduct.aftermarketProductCatalogDetails.paragraphDescription",
            productParagraphDescriptionDelta
          );
        } catch (e) {
          console.error("Error updating paragraph description", e);
        }

        const coverageDelta = createProductCoverageDelta(configuration);

        try {
          _.set(product, "aftermarketProduct.aftermarketProductCatalogDetails.coverageDetails", coverageDelta);
        } catch (e) {
          console.error("Error updating coverage details", e);
        }

        updateProductConfiguration({
          cost: finalRetailPricePerMonth,
          retailCost: finalRetailPricePerMonth,
          markup: { type: MarkupType.Flat, markup: 0 },
          contractResponse: response?.aftermarketProductDynamicPricing,
          aftermarketProduct: {
            aftermarketProductCatalogDetails: {
              paragraphDescription: productParagraphDescriptionDelta,
              productSummary: productSummaryDelta,
              coverageDetails: coverageDelta,
            },
          },
        });

        setLoadingDynamicPricing(false);
      }
    },
    onError() {
      setLoadingDynamicPricing(false);
    },
  });

  const data = useMemo<{ API_RESPONSE_DATA: { programCodes: GaigProgramCode[] } }>(() => {
    let productCriteria = _.get(
      product,
      "aftermarketProduct.criteria.[0].event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA",
      null
    );
    if (!productCriteria) {
      const productFromApi = products.find(p => p.proposalProductId === product.proposalProductId);
      productCriteria = _.get(
        productFromApi,
        "aftermarketProduct.criteria[0].event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA",
        null
      );
    }
    return productCriteria;
  }, [products]);

  const { uniqueAvailableAssets, filteredProgramCodes } = useMemo(() => {
    let uniqueAvailableAssets: GaigAvailableAsset[] = [];
    if (!data) {
      return {
        uniqueAvailableAssets,
        filteredProgramCodes: [],
      };
    }
    data?.API_RESPONSE_DATA?.programCodes?.forEach(program => {
      program.availableAssets.forEach(asset => {
        uniqueAvailableAssets.push(asset);
      });
    });
    uniqueAvailableAssets = _.uniqBy(uniqueAvailableAssets, asset => asset.code);

    let filteredProgramCodes: GaigProgramCode[] = [];
    if (proposalProductConfiguration?.availableAsset) {
      data?.API_RESPONSE_DATA?.programCodes?.forEach(program => {
        program.availableAssets.forEach(asset => {
          if (asset.code === proposalProductConfiguration.availableAsset) {
            filteredProgramCodes.push(program);
          }
        });
      });
    } else {
      data?.API_RESPONSE_DATA?.programCodes?.forEach(program => {
        filteredProgramCodes.push(program);
      });
    }
    filteredProgramCodes = _.uniqBy(filteredProgramCodes, program => program.ProgramCode);

    return {
      uniqueAvailableAssets,
      filteredProgramCodes,
    };
  }, [data, proposalProductConfiguration]);

  useEffect(() => {
    if (!proposalProductConfiguration?.contractTerm || !proposalProductConfiguration?.coverageTerm) return;

    setLoadingDynamicPricing(true);

    const startDate = startDateValue ? moment.default(startDateValue) : moment.default();

    let contractStartDate = startDate;
    let contractEndDate = (startDateValue ? moment.default(startDateValue) : moment.default()).add(
      proposalProductConfiguration.contractTerm,
      "months"
    );

    let coverageStartDate = startDate;
    let coverageEndDate = (startDateValue ? moment.default(startDateValue) : moment.default()).add(
      proposalProductConfiguration.coverageTerm,
      "months"
    );

    const assetCategory = GAIG_CATEGORIES.find(
      category => category.assetClass === proposalProductConfiguration.availableAsset
    );

    if (mode === ProposalProductCardModes.Constructor) {
      return;
    }

    getDynamicPricing({
      variables: {
        input: {
          aftermarketVendorApiChannel: AftermarketProductVendorApiChannel.Gaig,
          gaigData: {
            input: {
              quote: {
                billingFrequency: proposalProductConfiguration?.billingFrequency,
                contractStartDate: contractStartDate.toDate(),
                contractEndDate: contractEndDate.toDate(),
                coverageStartDate: coverageStartDate.toDate(),
                coverageEndDate: coverageEndDate.toDate(),
                contractNumber: v4(),
                userName: "TRNSACT",
                customer: {
                  customerName: creditApp.businessName ?? `${creditApp.firstName} ${creditApp.lastName}`,
                  customerNumber: creditApp.id ?? v4(),
                  customerAddress1: creditApp.address ?? "",
                  customerAddress2: "",
                  customerCity: creditApp.city ?? "",
                  customerStateProv: creditApp.state ?? "",
                  customerPostalCode: creditApp.postalCode ?? "",
                  customerCountry: "USA",
                  customerContactEmail: creditApp.email ?? "",
                  customerPhoneNumber: creditApp.phone ?? "",
                },
                vendor: {
                  vendorName: vendorProfile.name ?? "",
                  vendorNumber: vendorProfile.id ?? "",
                },
                assets: [
                  {
                    category: assetCategory?.category || proposalProductConfiguration?.availableAsset,
                    assetAmount: amountValue ? Number(amountValue) : 0,
                    programCode: proposalProductConfiguration?.programCode,
                  },
                ],
              },
            },
          },
        },
      },
    });
  }, [
    proposalProductConfiguration?.contractTerm,
    proposalProductConfiguration?.coverageTerm,
    proposalProductConfiguration?.availableAsset,
    proposalProductConfiguration?.selectedProgram,
  ]);

  const handleAvailableAssetChange = (selectedAsset: any) => {
    updateProductConfiguration({
      availableAsset: selectedAsset,
    });
  };

  const handleSelectedProgramChange = (programCode: string) => {
    const selectedProgram = filteredProgramCodes.find(program => program.ProgramCode === programCode);

    updateProductConfiguration({
      programCode,
      selectedProgram,
    });
  };

  const handleContractTermChange = (selectedContractTerm: number) => {
    updateProductConfiguration({
      contractTerm: selectedContractTerm,
    });
  };

  const handleCoverageTermChange = (selectedCoverageTerm: number) => {
    updateProductConfiguration({
      coverageTerm: selectedCoverageTerm,
      COVERAGE_TERM: `${selectedCoverageTerm} Months`,
    });
  };

  const handleBillingFrequencyChange = (selectedBillingFrequency: number) => {
    updateProductConfiguration({
      billingFrequency: selectedBillingFrequency,
    });
  };

  useEffect(() => {
    if (filteredProgramCodes.length === 1) {
      handleSelectedProgramChange(filteredProgramCodes[0].ProgramCode);
    }
  }, []);

  return (
    <ProposalCardContainer
      type={type}
      mode={mode}
      prisingConfig={prisingConfig}
      equipmentCost={equipmentCost}
      proposalProduct={product}
      isPricingEditable={false}
      logo={vendorsLogoByType["GAIG"]}
      isDynamicPricingLoading={isDynamicPricingLoading}
      productConfiguration={proposalProductConfiguration}
      updateProductConfiguration={updateProductConfiguration}
      factsSkipped={[]}>
      <Fields
        configuration={proposalProductConfiguration}
        uniqueAvailableAssets={uniqueAvailableAssets}
        filteredProgramCodes={filteredProgramCodes}
        handleAvailableAssetChange={handleAvailableAssetChange}
        handleSelectedProgramChange={handleSelectedProgramChange}
        handleContractTermChange={handleContractTermChange}
        handleCoverageTermChange={handleCoverageTermChange}
        handleBillingFrequencyChange={handleBillingFrequencyChange}
        isDynamicPricingLoading={isDynamicPricingLoading}
        mode={mode}
      />
    </ProposalCardContainer>
  );
};
