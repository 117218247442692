import * as _ from "lodash";
import { ProposalProductAddon } from "@trnsact/trnsact-shared-types";

export type FormattedLossCode = ProposalProductAddon & { value: boolean };

export function lossCodesFormatter(component: any, lossCode: any, value: boolean): FormattedLossCode {
  return {
    category: component.description,
    cost: lossCode.dealerCost,
    title: lossCode.description,
    retailCost: lossCode.suggestedRetailCost,
    thirdPartyId: _.isNumber(lossCode?.coverageLossCodeId) ? lossCode?.code : lossCode?.coverageLossCodeId?.toString(),
    value,
  };
}
